@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.pictureMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  max-height: $height-base;

  @include large-screen {
    max-height: $height-large-screen;
  }
}
