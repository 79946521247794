@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.radioButton {
  margin-bottom: 10px;
  width: 100%;
}

.radioButton__button {
  padding: 16px 10px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 325px;
  max-width: 100%;
  border-radius: 10px;
  background-color: $white;
  border: 1px solid var(--color-sa-action);
  text-align: center;
  color: $color-text;
  font-weight: 400;
  transition: 0.2s;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus-within {
    background-color: var(--color-sa-action);
    color: $white;
    font-weight: 700;
  }
}

.radioButton__input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.radioButton__label {
  text-transform: capitalize;
  color: inherit;
}
