@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.popin__container {
  position: fixed;
  margin: auto;
  z-index: 20;
  width: 100%;
  max-width: $width-base;
  height: 100%;
  max-height: $height-base;
  border-radius: 16px;
  overflow: hidden;
  pointer-events: none;

  @include large-screen {
    max-height: $height-large-screen;
  }

  &.is-active {
    pointer-events: all;
  }
}
.popin__underlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: 0.25s;
  .is-active & {
    opacity: 1;
    transition: 0.5s;
  }
}
.popin {
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding: 15px 20px;
  border-radius: 10px 10px 0 0;
  background-color: $white;
  transform: translate(-50%, 100%);
  transition: 0.25s;
  .is-active & {
    transform: translate(-50%, 0);
    transition: 0.5s;
  }
  min-height: 100px;
}
.popin__close {
  float: right;
  padding: 10px;
  color: $black;
}
