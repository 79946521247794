/*
** Media Queries
*/

$mq-mobile: 500px !default;

@mixin large-screen {
  @media (min-width: $mq-mobile) {
    @content;
  }
}
