@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.roundProgress {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 36px;

  &-number {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-circle {
    width: 100%;
    height: 100%;
    fill: transparent;

    &--reverse {
      transform: rotateY(180deg);
    }
  }

  &-circle-bg {
    stroke-width: 3px;
    stroke-linecap: round;
    stroke: #f4f5f7;
  }

  &-circle-fg {
    stroke-width: 3px;
    stroke-linecap: round;
    stroke: var(--color-sa-info);
    transition: 1s linear;
  }
}
