@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.cartStep {
  height: 100dvh;
  max-height: $height-base;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  @include large-screen {
    max-height: $height-large-screen;
  }
}

.cartStep__loading {
  flex-grow: 1;
  width: 100%;
}

.cartStep__submitLoading {
  align-items: baseline;
}

.cartStep__heading {
  padding-block: 32px 24px;
  padding-inline: 16px;

  @include large-screen {
    padding-inline: 24px;
  }
}

.cartStep__back {
  height: 32px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 16px;
  left: 12px;
  width: 100%;
  z-index: 1;

  @include large-screen {
    top: 20px;
    left: 32px;
  }

  &__link {
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 8px;
    background-color: $color-background-light;
    color: $color-title;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }

  &__icon {
    width: 5px;
    height: 10px;
    margin-right: 5px;
  }
}

.cartStep__visual {
  width: 100%;
}

.cartStep__visual__Image {
  width: 100%;
  object-fit: cover;
}

.cartStep__title {
  margin-bottom: 10px;
  text-align: left;
}

.cartStep__subtitle {
  font-size: 16px;
  line-height: 20px;
  color: var(--color-sa-info);
  text-align: left;
}

.cartStep__body {
  width: 100%;
  overflow: hidden;
}

.cartStep__slider {
  min-width: 100%;
  max-width: 500px;
  margin-top: 16px;
  padding-inline: 8px;

  :global(.slick-track) {
    display: flex;
  }

  :global(.slick-slide) {
    height: inherit;
    > div {
      display: flex;
      height: 100%;
    }
  }

  @include large-screen {
    padding-inline: 16px;
  }
}

.cartStep__buttonContainer {
  width: 100%;
  margin-top: auto;
  padding-block: 16px;
  background-color: $white;
}
