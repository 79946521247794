@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.personnalisationStep {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.personnalisationStep__title {
  max-width: 375px;
  margin: 16px;
  font-size: 24px;
  line-height: 30px;
  color: $color-title;
  text-align: center;
}

.personnalisationStep__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personnalisationStep__description {
  margin: 16px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: justify;
  font-size: 16px;
  line-height: 20px;

  @include large-screen {
    margin-inline: 32px;
  }
}

.personnalisationStep__consent {
  padding-bottom: 48px;

  input {
    vertical-align: top;
  }

  &-label {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
  }

  &-link {
    margin-left: 5px;
    color: var(--color-sa-action);
    font-weight: 700;
  }
}

.personnalisationStep__footer {
  margin-top: auto;
}

.personnalisationStep__footerCTA {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;

  .personnalisationStep__footerCTA__spinner {
    width: 20px;
  }
}

.personnalisationStep__footerCTA-abandon {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-height: 100px;
  font-size: 14px;
}

.emailInputContainer {
  padding-inline: 16px;

  @include large-screen {
    padding-inline: 32px;
  }
}

.sending {
  padding-inline: 32px;
}
