@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.scoreCard {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 8px;
    background-color: $color-background-light;
    border-radius: 26px;
    box-shadow: 0px 4px 10px 0px #0000000d;
    cursor: pointer;
  }

  &__summaryContainer {
    height: 50px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 4px 0px 4px 16px;
    gap: 8px;
  }

  &__name {
    flex-grow: 1;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__rating {
    width: 100px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    color: $white;
    text-transform: uppercase;
    text-align: center;
    border-radius: 22px;
  }

  &__openDetailsIcon {
    height: 16px;
    width: 16px;
  }
}

.excellent {
  background-color: $color-score-excellent;
}

.veryGood {
  background-color: $color-score-very-good;
}

.good {
  background-color: $color-score-good;
}

.average {
  background-color: $color-score-average;
}

.bad {
  background-color: $color-score-bad;
}

.veryBad {
  background-color: $color-score-very-bad;
}
