@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.title {
  margin-block: 0;
  font-family: 'Mulish', sans-serif;
  font-optical-sizing: auto;
  font-size: 24px;
  line-height: 30px;
  color: $color-title;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}
