@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.scoreDetailStep {
  width: 100%;
  max-width: $width-base;
  height: 100dvh;
  max-height: $height-base;

  @include large-screen {
    max-height: $height-large-screen;
  }
}
