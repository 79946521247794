@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.recommendationCardContainer {
  height: 100%;
  padding: 0 10px 10px 10px;
}

.recommendationCard {
  width: 255px;
  height: 100%;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px #6c034f26;
  overflow: hidden;
  opacity: 0;
  transition: 0.2s;

  &.recommendationCard--visible {
    opacity: 1;
  }
  // width: 100%;
}

.recommendationCard--fullWidth {
  max-width: 386px;
  width: 100%;
  margin: 0 auto;
}

.recommendationCard__body {
  width: 100%;
  padding: 0 16px 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: $white;
}

.recommendationCard__visual {
  width: 125px;
  height: 125px;
}

.recommendationCard__visual-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recommendationCard__content {
  width: 100%;
  margin-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  user-select: none;
}

.recommendationCard__title {
  color: $color-title;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}

.recommendationCard__brand {
  font-size: 14px;
  line-height: 15px;
}

.recommendationCard__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 14px;
}

.recommendationCard__descriptionExpanded {
  line-clamp: unset;
  -webkit-line-clamp: unset;
}

.recommendationCard__button {
  height: 44px;
  margin-bottom: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  text-transform: none;
  cursor: pointer;

  .recommendationCard--active & {
    color: $white;
  }

  &__checkIcon {
    width: 16px;
    height: 12px;
    path {
      fill: $white;
    }
  }

  &__cartIcon {
    width: 15px;
    height: 15px;
    path {
      fill: var(--color-sa-action);
    }
  }
}

.recommendationCard__footer-default {
  .recommendationCard--active & {
    display: none;
  }
}

.recommendationCard__footer-active {
  display: none;

  .recommendationCard--active & {
    display: block;
  }
}

.recommendationCard__seeMoreButton {
  background-color: transparent;
  border: none;
  width: fit-content;
  cursor: pointer;
  color: var(--color-sa-action);
  padding: 0;
  text-decoration: underline;
  font-size: 12px;
}
