@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.scoreDetail {
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      width: 0;
    }
  }

  &__summaryContainer {
    height: 50px;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    gap: 8px;
  }

  &__backButton {
    height: 24px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  &__backIcon {
    height: 24px;
    width: 24px;
  }

  &__name {
    flex-grow: 1;
    text-transform: uppercase;
  }

  &__rating {
    width: 100px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    color: $white;
    text-transform: uppercase;
    border-radius: 22px;
  }

  &__pictureContainer {
    width: 100%;
    height: 395px;
    padding-block: 16px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
  }

  &__picture {
    max-width: 262px;
    object-fit: cover;
    border-radius: 24px;
  }

  &__textContainer {
    margin: 16px;

    @include large-screen {
      margin-inline: 32px;
    }
  }

  &__backLinkContainer {
    margin: auto auto 16px auto;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    color: var(--color-sa-action);
    cursor: pointer;

    @include large-screen {
      margin-bottom: 24px;
    }
  }
}

.excellent {
  background-color: $color-score-excellent;
}

.veryGood {
  background-color: $color-score-very-good;
}

.good {
  background-color: $color-score-good;
}

.average {
  background-color: $color-score-average;
}

.bad {
  background-color: $color-score-bad;
}

.veryBad {
  background-color: $color-score-very-bad;
}
