@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.picturePreparationStep {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-height: $height-base;

  @include large-screen {
    max-height: $height-large-screen;
  }
}

.picturePreparationStep__visual {
  position: relative;
  width: 100%;
}

.picturePreparationStep__visual__image {
  width: 100%;
  object-fit: cover;
}

.picturePreparationStep__pictureFrame {
  max-height: 90%;
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translate(-50%);
}

.picturePreparationStep__content {
  height: 100%;
  margin-top: 32px;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include large-screen {
    padding-inline: 32px;
  }
}

.picturePreparationStep__warning {
  display: none;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  @include large-screen {
    display: flex;
    gap: 8px;
  }
}

.picturePreparationStep__warningText {
  font-size: 12px;
  line-height: 14px;
}

.picturePreparationStep__title {
  margin-bottom: 12px;
  text-align: unset;
}

.picturePreparationStep__description {
  margin: 12px 0 auto 0;
}

.picturePreparationStep__tipSection {
  margin-top: 18px;
}

.picturePreparationStep__tip {
  display: flex;
  margin-bottom: 10px;

  span {
    color: var(--color-sa-info);
    font-weight: bold;
  }
}

.picturePreparationStep__tipIcon {
  margin-right: 16px;
  path {
    fill: var(--color-sa-info);
  }
}

.picturePreparationStep__dataPrivacy {
  padding: 8px 12px;
  border: 1px solid var(--color-sa-action-translucent);
  border-radius: 8px;
}

.picturePreparationStep__dataPrivacy__title {
  margin-bottom: 8px;
  color: var(--color-sa-info);
}

.picturePreparationStep__dataPrivacy__text {
  font-size: 12px;
  line-height: 15px;
}

.picturePreparationStep__button {
  margin-block: 0 32px;
}
