@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.select {
  width: 100%;
}

.select__select {
  appearance: none;
  width: 100%;
  max-width: 100%;
  min-height: 68px;
  padding: 24px 16px;
  border: 1px solid var(--color-sa-action);
  border-radius: 10px;
  background-color: $white;
  outline: none;
  font-size: 20px;
}
