@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.spinner__icon {
  svg {
    width: 100%;
    height: 100%;
    color: transparent;
    fill: var(--color-sa-info);
    animation: SPIN 1.2s infinite linear;
  }
}

@keyframes SPIN {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
