@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.recommendationRoutine__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
}

.recommendationRoutine__title {
  display: flex;
  align-items: center;

  &__number {
    min-width: 32px;
    min-height: 32px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 20px;
    font-weight: bold;
    background-color: var(--color-sa-info);
    border-radius: 50%;
  }
}

.recommendationRoutine__body {
  width: 100%;
  max-height: fit-content;
  height: auto;
  transition: 0.2s;
  overflow: hidden;
}

.recommendationRoutine__slider {
  min-width: 100%;
  max-width: 500px;

  :global(.slick-track) {
    display: flex;
  }

  :global(.slick-slide) {
    height: inherit;
    > div {
      height: 100%;
    }
  }
}

.recommendationRoutine__footer {
  width: 100%;
  max-height: fit-content;
  height: auto;
  transition: 0.2s;
  overflow: auto;
}

.recommendationRoutine__subMore {
  font-size: 14px;
  color: var(--color-sa-action);
  margin: 14px 0 8px 10px;
  cursor: pointer;
}

.recommendationRoutine__subs {
  max-height: fit-content;
  height: auto;
  margin-top: 24px;
  padding-left: 16px;
  transition: 0.2s;
  overflow: auto;
}
