@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.multiStepForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.multiStepForm__inner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.multiStepForm__back {
  height: 32px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 16px;
  left: 12px;
  width: 100%;
  z-index: 1;

  @include large-screen {
    top: 20px;
    left: 32px;
  }
}

.multiStepForm__back-link {
  width: 70px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 8px;
  color: $color-title;
  font-size: 14px;
  background-color: $color-background-light;
  border-radius: 4px;
  cursor: pointer;
}

.multiStepForm__back-icon {
  width: 5px;
  height: 10px;
  margin-right: 5px;
}

.multiStepForm__body {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;

  &.is-transition {
    pointer-events: none;
  }

  &.is-prev {
    flex-direction: row-reverse;
  }
}

/* TODO Check if this can be moved to the scss file associated to the file where it is used:
** src/forms/PersonalizationStep/styles.module.scss */
.multiStepForm__step {
  flex-shrink: 0;

  .multiStepForm__body.is-transition & {
    transition: 0.3s;
    transform: translateX(-100%);
  }

  .multiStepForm__body.is-transition.is-prev & {
    transition: 0.3s;
    transform: translateX(100%);
  }
}

.multiStepForm__footer {
  padding-inline: 16px;
  padding-bottom: 16px;

  @include large-screen {
    padding-inline: 32px;
    padding-bottom: 32px;
  }
}

.multiStepForm__count {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  font-size: 14px;
  color: var(--color-sa-info);
}

.multiStepForm__footer__text {
  font-size: 12px;
  line-height: 15px;
  margin-top: 16px;
  color: $color-title;
  text-align: center;
}
