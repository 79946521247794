@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.diagnosisStep {
  width: 100%;
  max-width: $width-base;
  height: 100dvh;
  max-height: $height-base;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include large-screen {
    max-height: $height-large-screen;
  }
}

.diagnosisStep__main {
  height: $height-base - $height-diagnosis-step-footer;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background: center / cover no-repeat;

  @include large-screen {
    height: $height-large-screen - $height-diagnosis-step-footer;
  }
}

.diagnosisStep__colorLayer {
  width: 100%;
  height: 100%;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  background-color: $color-background-light;
  overflow-y: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
}

.diagnosisStep__title {
  margin-top: 20px;
  margin-bottom: 38px;
  align-self: flex-start;
  font-size: 18px;
  line-height: 22px;

  @include large-screen {
    margin-top: 24px;
    margin-bottom: 64px;
  }
}

.diagnosisStep__scoreContainer {
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;

  @include large-screen {
    padding-bottom: 16px;
  }
}

.diagnosisStep__details {
  margin: auto auto 0 auto;
  font-size: 12px;
  line-height: 15px;
}

.diagnosisStep__footer {
  width: 100%;
  height: $height-diagnosis-step-footer;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $white;
}

.diagnosisStep__button {
  padding-inline: 16px;

  @include large-screen {
    padding-inline: 48px;
  }
}

.diagnosisStep__loading {
  flex-grow: 1;
  width: 100%;
}
