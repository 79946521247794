/*
** Non-customizable colors
*/
$color-title: #3c3636;
$color-text: #4f4f4f;
$color-background: #f9f9f9;
$color-background-light: #ffffffb3;
$color-disabled: #cdcfd1;

$white: #ffffff;
$black: #000000;
$grey: #bdbdbd;

$color-score-excellent: #1f9f56;
$color-score-very-good: #79bc95;
$color-score-good: #79b4bc;
$color-score-average: #f2c94c;
$color-score-bad: #f2994a;
$color-score-very-bad: #eb5757;

/*
** Customizable colors
*/
:root {
  --color-sa-action: #3e3e7c;
  --color-sa-action-translucent: #3e3e7c80;
  --color-sa-action-light: #f4f8fe;
  --color-sa-action-dark: #252563;
  --color-sa-info: #d9059e;
}

/*
** Dimensions
*/
$width-base: 450px;
$height-base: 667px;
$height-large-screen: 720px;

$height-picture-step-footer: 120px;
$height-diagnosis-step-footer: 98px;
