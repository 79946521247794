@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.inner {
  width: 100%;
  max-width: 700px;
  margin-inline: auto;
}

.inner--very-full {
  padding-inline: 0;
  max-width: none;
}

.inner--full {
  padding-inline: 0;
}

.inner--lg {
  padding-inline: 30px;
}

.inner--md {
  padding-inline: 60px;
}

.inner--sm {
  padding-inline: 120px;
}
