@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.pictureNonMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  max-height: $height-base;

  @include large-screen {
    max-height: $height-large-screen;
  }
}

.pictureNonMobile__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
}

.pictureNonMobile__videoContainer {
  max-width: $width-base;
  display: flex;
  flex: 1;
}

.pictureNonMobile__video {
  position: relative;
  width: 100%;
  overflow: hidden;

  &.is-picture {
    background-color: $white;
  }

  input {
    position: absolute;
    opacity: 0;
  }
}

.pictureNonMobile__popin--error {
  display: flex;
  flex-direction: column;

  :first-child {
    font-weight: 700;
    color: var(--color-sa-action);
  }

  p {
    margin-top: 15px;

    word-break: break-word;
    width: 100%;
    padding-bottom: 15px;
    text-align: left;
  }

  span {
    text-decoration: underline;
    margin-top: 20px;
    display: block;
    color: var(--color-sa-action);
  }
}

.pictureNonMobile__video-flash {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: 0;
  pointer-events: none;
  transition: 0.08s;

  &.is-active {
    opacity: 1;
  }
}

.pictureNonMobile__video-countdown {
  display: none;
  position: absolute;
  right: 8%;
  top: 8%;
  z-index: 10;
  color: $white;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  &.is-active {
    display: block;
  }

  .pictureNonMobile__video.is-picture & {
    display: none;
  }

  &-text {
    width: 100% !important;
    height: 100% !important;
  }
}

.pictureNonMobile__video-scan {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #cce7ff 100%);
  transition: 0.8s;
  opacity: 0;
  animation: SCAN 18s;

  &.is-active {
    opacity: 1;
  }
}

@keyframes SCAN {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(100vh + 40px));
  }
}

.pictureNonMobile__video-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  height: 77%;
  width: 94%;
  color: $white;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  transition: 0.1s;

  @include large-screen {
    height: 71%;
    width: 78%;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .pictureNonMobile__video.is-picture & {
    opacity: 0;
  }
}

.pictureNonMobile__video-input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.2s 0.2s;
  transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);

  .pictureNonMobile__video.is-picture & {
    opacity: 0;
  }
}

.pictureNonMobile__video-image {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;

  .pictureNonMobile__video.is-picture & {
    z-index: 3;
  }
}

.pictureNonMobile__video-canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;

  .pictureNonMobile__video.is-picture & {
    display: none;
  }
}

.pictureNonMobile__footerContainer {
  width: 100%;
  height: $height-picture-step-footer;
}
/* 
.pictureNonMobile__step {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .pictureNonMobile__body.is-transition & {
    transition: 0.5s;
    transform: translateX(-100%);
  }

  .pictureNonMobile__body.is-transition.is-prev & {
    transition: 0.5s;
    transform: translateX(100%);
  }

  &--centered {
    justify-content: center;
  }
} */

.pictureNonMobile__step-content {
  width: 100%;
  height: $height-picture-step-footer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.pictureNonMobile__step-title {
  width: 100%;
  height: $height-picture-step-footer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.pictureNonMobile__countdown {
  font-size: 64px;
  line-height: 80px;
  color: var(--color-sa-action);
  opacity: 0;

  &.isActive {
    opacity: 1;
  }
}

.pictureNonMobile__step-hiddenContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-height: 0;
  transition: 0.5s 0.5s;
  overflow: hidden;

  &.is-active {
    max-height: 300px;
  }
}

.pictureNonMobile__step-description {
  width: 100%;
  height: $height-picture-step-footer;
  padding-inline: 16px;
  display: flex;
  align-items: center;

  @include large-screen {
    padding-inline: 24px;
  }
}

.pictureNonMobile__step__miniature {
  margin-right: 8px;
  img {
    width: 87px;
  }
}

.pictureNonMobile__step__hint {
  color: var(--color-sa-info);
}

.pictureNonMobile__step-button {
  width: 538px;
  margin-bottom: 0;
}

.pictureNonMobile__step-footer {
  text-align: center;
  font-size: 14px;
  line-height: 17px;

  span {
    cursor: pointer;
  }
}

.pictureNonMobile__step-footerCTA {
  margin-bottom: 62px;
}

.pictureNonMobile__step-footerCTA-alt {
  width: 100%;
  height: $height-picture-step-footer;
  padding-inline: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;

  @include large-screen {
    padding-inline: 32px;
  }
}

.pictureNonMobile__step-footerCTAButton {
  max-width: 164px;
  margin-inline: 0;
  padding-inline: 32px;
  gap: 10px;
  flex-grow: 1;

  @include large-screen {
    max-width: 185px;
  }
}

.pictureNonMobile__step-footerRetakeButton {
  padding-inline: 16px;

  &:hover .pictureNonMobile__retakeArrow {
    path {
      stroke: $white;
    }
  }
}

.pictureNonMobile__analysisStep__container {
  height: $height-picture-step-footer;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  gap: 8px;
}

.pictureNonMobile__analysisStep__textContent {
  max-width: 265px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > p {
    color: var(--color-sa-info);
  }

  @include large-screen {
    max-width: 340px;
  }
}

.pictureNonMobile__analysis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 26px;
  overflow: hidden;
  width: 100%;
}

.pictureNonMobile__analysis__progress {
  width: 80px;
  font-size: 20px;
  flex: 1;
}

.pictureNonMobile__analysis__check {
  width: 32px;
  height: 32px;
  margin: 0 auto 24px auto;
  color: var(--color-sa-action);
}

.pictureNonMobile__analysis__container {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  transition-property: all;
  transition-timing-function: ease-in-out;
}

.pictureNonMobile__analysis__container--item {
  width: 100%;
  flex: 0 0 100%;
  font-size: 12px;
  line-height: 15px;
}

.pictureNonMobile__diagnosisStep__title {
  margin-top: 22px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  color: var(--color-sa-info);
}

.pictureNonMobile__diagnosisStep__button {
  padding-block: 8px;
}
