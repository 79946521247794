@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";/* TODO Remove margin to make the component more easily reusable *
 * Need to check potential layout shift when doing this **********/
.button {
  height: 52px;
  margin: auto;
  padding-inline: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: $white;
  background-color: var(--color-sa-action);
  gap: 16px;
  border: 1px solid var(--color-sa-action);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: var(--color-sa-action-dark);
    border-color: var(--color-sa-action-dark);
  }

  &--secondary {
    background-color: var(--color-sa-action-light);
    border-color: var(--color-sa-action-light);
    color: var(--color-sa-action);

    &:hover {
      color: var(--color-sa-action);
      background-color: var(--color-sa-action-light);
      border-color: var(--color-sa-action);
    }
  }

  &--rounded {
    border-radius: 60px;
  }

  &--fullWidth {
    width: 100%;
  }

  &--outline {
    background-color: transparent;
    color: var(--color-sa-action);

    &:hover {
      background-color: var(--color-sa-action);
      color: $white;
    }
  }

  &.is-disabled,
  &:disabled {
    background-color: $color-disabled;
    border-color: $color-disabled;
    color: $white;
    pointer-events: none;
  }
}
